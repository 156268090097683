.App {
  text-align: center;
  background-color: #009688;
  height: 100vh;
}

.App-logo {
  height: 20vmin;
  pointer-events: none;
  display: inline-block;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 8s linear;
  }
}

.App-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  min-height: calc(100vh - 34px - 1em);
}
footer {
  width: 100vw;
  text-align: center;
  color: rgba(255, 255, 255, 0.8);
  padding-bottom: 1em;
}
footer a {
  color: rgba(255, 255, 255, 0.8);
  text-decoration: none;
}
.pulsate {
  animation: pulsate infinite 2s linear;
}

@keyframes App-logo-spin {
  10% {
    transform: rotate(0deg);
  }
  20% {
    transform: rotate(30deg);
  }
  60% {
    transform: rotate(-30deg);
  }

  90% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

@keyframes pulsate {
  0% {
    opacity: 0.19;
  }
  5% {
    opacity: 0.2;
  }
  10% {
    opacity: 0.25;
  }
  15% {
    opacity: 0.35;
  }

  25% {
    opacity: 0.2;
  }
  30% {
    opacity: 0.4;
  }
  35% {
    opacity: 0.1;
  }
  40% {
    opacity: 0.25;
  }
  45% {
    opacity: 0.2;
  }
  60% {
    opacity: 0.4;
  }
  70% {
    opacity: 0.35;
  }
  80% {
    opacity: 0.4;
  }
  90% {
    opacity: 0.2;
  }
  100% {
    opacity: 0.4;
  }
}
